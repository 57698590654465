import React, { FunctionComponent } from 'react'
import Layout from '../components/LayoutBlog'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { SEO } from '../components/SEO'

interface ErrorProps {
  location: Location
}

const Error = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  height: 75vh;
  text-align: center;
`

const ErrorTitle = styled.h1`
  display: block;
  font-size: 8em;
  font-weight: bold;
  opacity: 0.45;
  width: 100%;
  margin: 0 0 15px;
`

const ErrorDescription = styled.h2`
  font-size: 1.8em;
  display: block;
  width: 100%;
  margin-bottom: 15px;
`

const BackLink = styled(Link)`
  color: ${props => props.theme.layout.primaryColor};

  &:hover {
    text-decoration: underline;
  }
`

const NotFoundPage: FunctionComponent<ErrorProps> = ({ location }) => (
  <Layout bigHeader={false}>
    <SEO location={location} title={`Página não encontrada`} />
    <Error>
      <ErrorTitle>404</ErrorTitle>
      <ErrorDescription>Página não encontrada</ErrorDescription>
      <BackLink to={`/blog`}>
        {/* Country roads... */}Voltar para a página principal
      </BackLink>
    </Error>
  </Layout>
)

export default NotFoundPage
